import { useEffect } from "react";
import i18n from "../../common/languageConf";
import GlobalSettingStore from "../../Stores/GlobalSettingStore";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";

function ZhangguPage() {
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [GlobalSettingStore.GlobalSetting])
    return (
        <>

            <section className="header-title">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h2>{i18n.t("zhanggu")}</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-nav">
                <div className="container">
                    <div className="row">
                        <ul>
                            <li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/"}>{i18n.t("HOME")}</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/companies"}>{i18n.t("temsilcilikler")}</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><span>{i18n.t("zhanggu")}</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="projects-section">
                <div className="container">
                    <div className="row">
                        <div className="thm-container">
                            <div className="col-md-4 mix item enerji">
                                <Link to="/companies/zhanggu/zhangguco">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/shandongimg/zhanggucompanyimg.png"
                                                alt="Zhanggu Logo"
                                                style={{ objectFit: "cover", width: "100%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                {i18n.t("zhanggu")}
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                {i18n.t("shandong")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div>
                            {/* <div className="col-md-4 mix item enerji">
                                <Link to="/companies/zhanggu/zhanggucomprehensive">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/shandongimg/ZhangguCardimg.jpg"
                                                alt="zhanggucomprehensive"
                                                style={{ objectFit: "cover", width: "100%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                Comprehensive Brochure
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                    Shandong Zhangqiu Blower Co.,Ltd
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div> */}
                            <div className="col-md-4 mix item enerji">
                                <Link to="/companies/zhanggu/zhanggufan">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/shandongimg/FanCardimg.png"
                                                alt="ZhangguFan"
                                                style={{ objectFit: "cover", width: "80%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                {i18n.t("fan")}
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                {i18n.t("shandong")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-md-4 mix item enerji">
                                <Link to="/companies/zhanggu/zhanggumvr">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/shandongimg/mvrCardimg.png"
                                                alt="zhanggumvr"
                                                style={{ objectFit: "cover", width: "100%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                {i18n.t("mvrbrochure")}
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                {i18n.t("shandong")}
                                                 </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-md-4 mix item enerji">
                                <Link to="/companies/zhanggu/rootsblower">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/shandongimg/rootblowersCardimg.png"
                                                alt="rootsblower"
                                                style={{ objectFit: "cover", width: "100%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                {i18n.t("rootsblower")}
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                {i18n.t("shandong")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-md-4 mix item enerji">
                                <Link to="/companies/zhanggu/rotaryvalve">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/shandongimg/rotaryvalveCardimg.png"
                                                alt="rotaryvalve"
                                                style={{ objectFit: "cover", width: "100%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                {i18n.t("rotaryvalve")}
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                {i18n.t("shandong")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-md-4 mix item enerji">
                                <Link to="/companies/zhanggu/turboblower">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/shandongimg/turboblowerCardimg.png"
                                                alt="turboblower"
                                                style={{ objectFit: "cover", width: "100%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                {i18n.t("turboblower")}
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                {i18n.t("shandong")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="overview">
                <div className="container">
                    <div className="row">
                        <div className="video-responsive" style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <video width="400" controls>
                                <source src="./../../video/SZBProductFamily.mp4" type="video/mp4" />
                                    Tarayıcınız bu videoyu desteklemiyor.
                            </video>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

const Zhanggu = observer(ZhangguPage);
export default Zhanggu;
