import { useEffect, useState } from "react";
import i18n from "../../common/languageConf";
import GlobalSettingStore from "../../Stores/GlobalSettingStore";
import { observer } from "mobx-react";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import { Link } from 'react-router-dom';

import "yet-another-react-lightbox/plugins/thumbnails.css";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import MixItUp from 'react-mixitup';
import $ from 'jquery'; // jQuery'yi içe aktarın
import slides from "../slides";



function ProjectsHeader() {
    
    const [open, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [width, setWidth] = useState(120);
    const [height, setHeight] = useState(80);
    const [border, setBorder] = useState(1);
    const [borderRadius, setBorderRadius] = useState(4);
    const [padding, setPadding] = useState(4);
    const [gap, setGap] = useState(16);
    const [preload, setPreload] = useState(2);
    const [showToggle, setShowToggle] = useState(false);
    const [position, setPosition] = useState<
        "top" | "bottom" | "start" | "end"
    >("bottom");
    useEffect(() => {

    }, [GlobalSettingStore.GlobalSetting])


    return (
        <>
            <section className="header-title">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h2>{i18n.t("OurProjects")}</h2>
                        </div>
                    </div>
                </div>
            </section><section className="page-nav">
                <div className="container">
                    <div className="row">
                        <ul>
                            <li><Link style={{fontWeight:"bold", fontSize:"14px"}} to={"/"}> {i18n.t("HOME")}</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><span> {i18n.t("PROJECTS")}</span></li>
                            
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

const SolutionPageComponent = observer(ProjectsHeader);
export default SolutionPageComponent;