import React, { useEffect, useRef, useState } from 'react';
import GlobalSettingStore from '../../Stores/GlobalSettingStore';




const PneumaticConveyingSystem: React.FC = () => {

  // iframe için bir referans oluşturuyoruz
const iframeRef = useRef(null);

// Tam ekranı açmak için bir fonksiyon oluşturuyoruz
const openFullscreen = (elem: { requestFullscreen: () => void; mozRequestFullScreen: () => void; webkitRequestFullscreen: () => void; msRequestFullscreen: () => void; }) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { // Firefox
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { // IE/Edge
    elem.msRequestFullscreen();
  }
};

  // iframe'e tıklandığında tam ekran yapmak için fonksiyon
  const handleIframeClick = () => {
      if (iframeRef.current) {
        openFullscreen(iframeRef.current);
      }
    };

  const [numPages, setNumPages] = useState<number | null>(null); // numPages state'inin tipi açıkça belirtilmiş
  const [error, setError] = useState("null");

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages); // Burada numPages'in türünü açıkça tanımladık
  };

  const onDocumentLoadError = (error: any) => {
    console.error("PDF yükleme hatası:", error);
    setError("PDF yüklenirken bir hata oluştu.");
  };
  useEffect(() => {
    window.scrollTo(0, 0);

}, [GlobalSettingStore.GlobalSetting])
  return (
    <>
      <div>
      <iframe src="/pdfjs/web/viewer.html?file=/pdf/ProductsCatalogue/RotaryValve/PneumaticConveyingSystem.pdf" 
        width="100%"
        height="900" 
        frameBorder="0" 
        scrolling="no"></iframe>
       
      </div>
      <div>

      </div>
    </>
  );
};

export default PneumaticConveyingSystem;
