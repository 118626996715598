import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import GlobalSettingStore from "../../Stores/GlobalSettingStore";
import i18n from "../../common/languageConf";
import AirFoilBearing from "./AirFoilBearingTurbo2023";
import BseriesSingleStage from "./BseriesSingleStageHighSpeedCentrifugalBlower";
import CMultistageBlower from "./CMultistageCentrifugalBlower";
import MagnetcSuspensionBlower from "./MagneticSuspensionCentrifugalBlower";
import MCSeriesMulti from "./MCSeriesMultistageCentrifugalBlower";
import SteamCompressor from "./SteamCompressor";
import TBSeriesVacuumPump from "./TBSeriesVacuumPump";




function TurboBlower() {

    const [solution1, setsolution1] = useState(true)
    const [solution2, setsolution2] = useState(false)
    const [solution3, setsolution3] = useState(false)
    const [solution4, setsolution4] = useState(false)
    const [solution5, setsolution5] = useState(false)
    const [solution6, setsolution6] = useState(false)
    const [solution7, setsolution7] = useState(false)



    useEffect(() => {
        window.scrollTo(0, 0);

    }, [GlobalSettingStore.GlobalSetting])

    function selected(value: string) {
        setsolution1(false)
        setsolution2(false)
        setsolution3(false)
        setsolution4(false)
        setsolution5(false)
        setsolution6(false)
        setsolution7(false)


        if (value == "1") {
            setsolution1(true)
        }
        if (value == "2") {
            setsolution2(true)
        }
        if (value == "3") {
            setsolution3(true)
        }
        if (value == "4") {
            setsolution4(true)
        }
        if (value == "5") {
            setsolution5(true)
        }
        if (value == "6") {
            setsolution6(true)
        }
        if (value == "7") {
            setsolution7(true)
        }


    }
    return (
        <>
            <section className="header-title">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h2>{i18n.t("turboblower")}</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-nav">
                <div className="container">
                    <div className="row">
                        <ul>

                            <li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/"}> {i18n.t("HOME")}</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/companies/zhanggu"}>ZHANGGU</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><span>{i18n.t("turboblower")}</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="Mechanical-engineering">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="special-links">
                                <ul>
                                    <li><a className={solution1 ? "active" : ""} style={{ cursor: "pointer" }} onClick={() => { selected("1") }}>{i18n.t("AirFoilBearing")}</a></li>
                                    <li><a className={solution2 ? "active" : ""} onClick={() => { selected("2") }} style={{ cursor: "pointer" }}>{i18n.t("BseriesSingleStage")}</a></li>
                                    <li><a className={solution3 ? "active" : ""} onClick={() => { selected("3") }} style={{ cursor: "pointer" }}>{i18n.t("CMultistageBlower")}</a></li>
                                    <li><a className={solution4 ? "active" : ""} onClick={() => { selected("4") }} style={{ cursor: "pointer" }}>{i18n.t("MagnetcSuspensionBlower")}</a></li>
                                    <li><a className={solution5 ? "active" : ""} onClick={() => { selected("5") }} style={{ cursor: "pointer" }}>{i18n.t("MCSeriesMulti")}</a></li>
                                    <li><a className={solution6 ? "active" : ""} onClick={() => { selected("6") }} style={{ cursor: "pointer" }}>{i18n.t("SteamCompressor")}</a></li>
                                    <li><a className={solution7 ? "active" : ""} onClick={() => { selected("7") }} style={{ cursor: "pointer" }}>{i18n.t("TBSeriesVacuumPump")}</a></li>


                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {solution1 && <AirFoilBearing />}
                            {solution2 && <BseriesSingleStage />}
                            {solution3 && <CMultistageBlower />}
                            {solution4 && <MagnetcSuspensionBlower />}
                            {solution5 && <MCSeriesMulti />}
                            {solution6 && <SteamCompressor />}
                            {solution7 && <TBSeriesVacuumPump />}


                            <br />
                            <br />

                            <div className="questions-sol">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const TurboBlowerPageComponent = observer(TurboBlower);
export default TurboBlowerPageComponent;