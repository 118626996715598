import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import GlobalSettingStore from "../../Stores/GlobalSettingStore";
import i18n from "../../common/languageConf";
import PneumaticConveyingSystem from "./PneumaticConveyingSystem";
import RotaryValve from "./RotaryValve2014";



function RotaryValvePage() {

    const [solution1, setsolution1] = useState(true)
    const [solution2, setsolution2] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0);

    }, [GlobalSettingStore.GlobalSetting])

    function selected(value: string) {
        setsolution1(false)
        setsolution2(false)

        if (value == "1") {
            setsolution1(true)
        }
        if (value == "2") {
            setsolution2(true)
        }

    }
    return (
        <>
            <section className="header-title">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h2>{i18n.t("rotaryvalve")}</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-nav">
                <div className="container">
                    <div className="row">
                        <ul>

                            <li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/"}> {i18n.t("HOME")}</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/companies/zhanggu"}>ZHANGGU</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><span>{i18n.t("rotaryvalve")}</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            
            <section className="Mechanical-engineering">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="special-links">
                                <ul>
                                    
                                    <li><a className={solution1 ? "active" : ""} style={{ cursor: "pointer" }} onClick={() => { selected("1") }}>{i18n.t("PneumaticConveyingSystem")}</a></li>
                                    <li><a className={solution2 ? "active" : ""} onClick={() => { selected("2") }} style={{ cursor: "pointer" }}>{i18n.t("rotaryvalve")}</a></li>


                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {solution1 && <PneumaticConveyingSystem />}
                            {solution2 && <RotaryValve />}

                            <br />
                            <br />

                            <div className="questions-sol">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const RotaryValvePageComponent = observer(RotaryValvePage);
export default RotaryValvePageComponent;