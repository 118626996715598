import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import GlobalSettingStore from "../../Stores/GlobalSettingStore";
import i18n from "../../common/languageConf";
import Hseriesrootsblower from "./3HSeriesRootsBlower";
import Eurusmbblowers from "./EURUSMBBlowersBrochure";
import EurusZZBrochure from "./EurusZZBrochure";
import LseriesRootsBlower from "./LseiesRootsBlower";
import RMGRootsBlower from "./RMGRootsBlower";
import RRRootsBlower from "./RRRootsBlower";
import SSRKRootsBlower from "./SSRKRootsBlower";
import SSRRotaryBlower from "./SSRRotaryBlower";
import VRsteamvaporcompressor from "./VRsteamvaporcompressor";
import ZARotsBlower from "./ZARootsBlower";
import ZGBSeries from "./ZGBSeries";
import ZGSeriesRootsBlower from "./ZGSeriesRootsBlower";
import ZHSeriesBlower from "./ZHSeriesBlower";
import ZMRZMHSeries from "./ZMRZMHSeries";
import ZRseriesRootsBlower from "./ZRseriesRootsBlower";
import ZSHVZLBSeriesVacuumPump from "./ZSHVZLBSeriesVacuumPump";
import ZWRootsBlower from "./ZWRootsBlower";
import SSRNRootsBlower from "./SSRNRootsBlower";




function RootsBlowerPage() {

    const [solution1, setsolution1] = useState(true)
    const [solution2, setsolution2] = useState(false)
    const [solution3, setsolution3] = useState(false)
    const [solution4, setsolution4] = useState(false)
    const [solution5, setsolution5] = useState(false)
    const [solution6, setsolution6] = useState(false)
    const [solution7, setsolution7] = useState(false)
    const [solution8, setsolution8] = useState(false)
    const [solution9, setsolution9] = useState(false)
    const [solution10, setsolution10] = useState(false)
    const [solution11, setsolution11] = useState(false)
    const [solution12, setsolution12] = useState(false)
    const [solution13, setsolution13] = useState(false)
    const [solution14, setsolution14] = useState(false)
    const [solution15, setsolution15] = useState(false)
    const [solution16, setsolution16] = useState(false)
    const [solution17, setsolution17] = useState(false)
    const [solution18, setsolution18] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0);

    }, [GlobalSettingStore.GlobalSetting])

    function selected(value: string) {
        setsolution1(false)
        setsolution2(false)
        setsolution3(false)
        setsolution4(false)
        setsolution5(false)
        setsolution6(false)
        setsolution7(false)
        setsolution8(false)
        setsolution9(false)
        setsolution10(false)
        setsolution11(false)
        setsolution12(false)
        setsolution13(false)
        setsolution14(false)
        setsolution15(false)
        setsolution16(false)
        setsolution17(false)
        setsolution18(false)


        if (value == "1") {
            setsolution1(true)
        }
        if (value == "2") {
            setsolution2(true)
        }
        if (value == "3") {
            setsolution3(true)
        }
        if (value == "4") {
            setsolution4(true)
        }
        if (value == "5") {
            setsolution5(true)
        }
        if (value == "6") {
            setsolution6(true)
        }
        if (value == "7") {
            setsolution7(true)
        }
        if (value == "8") {
            setsolution8(true)
        }
        if (value == "9") {
            setsolution9(true)
        }
        if (value == "10") {
            setsolution10(true)
        }
        if (value == "11") {
            setsolution11(true)
        }
        if (value == "12") {
            setsolution12(true)
        }
        if (value == "13") {
            setsolution13(true)
        }
        if (value == "14") {
            setsolution14(true)
        }
        if (value == "15") {
            setsolution15(true)
        }
        if (value == "16") {
            setsolution16(true)
        }
        if (value == "17") {
            setsolution17(true)
        }
        if (value == "18") {
            setsolution18(true)
        }


    }
    return (
        <>
            <section className="header-title">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h2>{i18n.t("rootsblower")}</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-nav">
                <div className="container">
                    <div className="row">
                        <ul>

                            <li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/"}> {i18n.t("HOME")}</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/companies/zhanggu"}>{i18n.t("zhanggu")}</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><span>{i18n.t("rootsblower")}</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="Mechanical-engineering">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="special-links">
                                <ul>
                                    <li><a className={solution1 ? "active" : ""} style={{ cursor: "pointer" }} onClick={() => { selected("1") }}>{i18n.t("HSeriesRootsBlower")}</a></li>
                                    <li><a className={solution2 ? "active" : ""} onClick={() => { selected("2") }} style={{ cursor: "pointer" }}>{i18n.t("EURUSMBBlowersBrochure")}</a></li>
                                    <li><a className={solution3 ? "active" : ""} onClick={() => { selected("3") }} style={{ cursor: "pointer" }}>{i18n.t("EurusZZBrochure")}</a></li>
                                    <li><a className={solution4 ? "active" : ""} onClick={() => { selected("4") }} style={{ cursor: "pointer" }}>{i18n.t("LseriesRootsBlower")}</a></li>
                                    <li><a className={solution5 ? "active" : ""} onClick={() => { selected("5") }} style={{ cursor: "pointer" }}>{i18n.t("RMGRootsBlower")}</a></li>
                                    <li><a className={solution6 ? "active" : ""} onClick={() => { selected("6") }} style={{ cursor: "pointer" }}>{i18n.t("RRRootsBlower")}</a></li>
                                    <li><a className={solution7 ? "active" : ""} onClick={() => { selected("7") }} style={{ cursor: "pointer" }}>{i18n.t("SSRKRootsBlower")}</a></li>
                                    <li><a className={solution8 ? "active" : ""} onClick={() => { selected("8") }} style={{ cursor: "pointer" }}>{i18n.t("SSRRotaryBlower")}</a></li>
                                    <li><a className={solution9 ? "active" : ""} onClick={() => { selected("9") }} style={{ cursor: "pointer" }}>{i18n.t("SSRNRootsBlower")}</a></li>
                                    <li><a className={solution10 ? "active" : ""} onClick={() => { selected("10") }} style={{ cursor: "pointer" }}>{i18n.t("VRsteamvaporcompressor")}</a></li>
                                    <li><a className={solution11 ? "active" : ""} onClick={() => { selected("11") }} style={{ cursor: "pointer" }}>{i18n.t("ZARotsBlower")}</a></li>
                                    <li><a className={solution12 ? "active" : ""} onClick={() => { selected("12") }} style={{ cursor: "pointer" }}>{i18n.t("ZGBSeries")}</a></li>
                                    <li><a className={solution13 ? "active" : ""} onClick={() => { selected("13") }} style={{ cursor: "pointer" }}>{i18n.t("ZGSeriesRootsBlower")}</a></li>
                                    <li><a className={solution14 ? "active" : ""} onClick={() => { selected("14") }} style={{ cursor: "pointer" }}>{i18n.t("ZHSeriesBlower")}</a></li>
                                    <li><a className={solution15 ? "active" : ""} onClick={() => { selected("15") }} style={{ cursor: "pointer" }}>{i18n.t("ZMRZMHSeries")}</a></li>
                                    <li><a className={solution16 ? "active" : ""} onClick={() => { selected("16") }} style={{ cursor: "pointer" }}>{i18n.t("ZRseriesRootsBlower")}</a></li>
                                    <li><a className={solution17 ? "active" : ""} onClick={() => { selected("17") }} style={{ cursor: "pointer" }}>{i18n.t("ZSHVZLBSeriesVacuumPump")}</a></li>
                                    <li><a className={solution18 ? "active" : ""} onClick={() => { selected("18") }} style={{ cursor: "pointer" }}>{i18n.t("ZWRootsBlower")}</a></li>


                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {solution1 && <Hseriesrootsblower />}
                            {solution2 && <Eurusmbblowers />}
                            {solution3 && <EurusZZBrochure />}
                            {solution4 && <LseriesRootsBlower />}
                            {solution5 && <RMGRootsBlower />}
                            {solution6 && <RRRootsBlower />}
                            {solution7 && <SSRKRootsBlower />}
                            {solution8 && <SSRRotaryBlower />}
                            {solution9 && <SSRNRootsBlower />}
                            {solution10 && <VRsteamvaporcompressor />}
                            {solution11 && <ZARotsBlower />}
                            {solution12 && <ZGBSeries />}
                            {solution13 && <ZGSeriesRootsBlower />}
                            {solution14 && <ZHSeriesBlower />}
                            {solution15 && <ZMRZMHSeries />}
                            {solution16 && <ZRseriesRootsBlower />}
                            {solution17 && <ZSHVZLBSeriesVacuumPump />}
                            {solution18 && <ZWRootsBlower />}
                            


                            <br />
                            <br />

                            <div className="questions-sol">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const RootsBlowerPageComponent = observer(RootsBlowerPage);
export default RootsBlowerPageComponent;