import { useEffect, useState } from "react";
import i18n from "../../common/languageConf";
import GlobalSettingStore from "../../Stores/GlobalSettingStore";
import { observer } from "mobx-react";

function SolutionMaden() {

	useEffect(() => {

	}, [GlobalSettingStore.GlobalSetting])

	
	return (
		<>
				<div className="container">
					<div className="row">
						
						<div className="col-md-9">
							<div className="img-box">
								<div className="first"><img style={{ width: "100%", height: "100%", }} src="img/cozumler_maden.png" alt="mining project" /> </div>
								<div className="second"><img style={{ width: "100%", height: "100%",  objectFit:"cover" }} src="img/tamamlananprojeler/maden1.jpg" alt="mining project" /> </div>
							</div>
							<div className="text" style={{ whiteSpace: 'pre-line' }}>
								{i18n.translate("miningtext")}
							</div>
						
						
							<div className="questions-sol">
								
							</div>
						</div>
					</div>
				</div>
		</>
	);
}

const SolutionMadenComponent = observer(SolutionMaden);
export default SolutionMadenComponent;