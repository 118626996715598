import { useEffect, useState } from "react";
import i18n from "../common/languageConf";
import GlobalSettingStore from "../Stores/GlobalSettingStore";
import { observer } from "mobx-react";
import { Link } from 'react-router-dom';
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
// @ts-ignore
import mixitup from "mixitup";
import ProjectsHeader from "../components/projects/ProjectsHeader";


function ProjectPage() {
	useEffect(() => {
		// @ts-ignore
		var mixer = mixitup('.thm-container');
		var mixer = mixitup('.thm-container', {
			selectors: {
				target: '.blog-item'
			},
			animation: {
				duration: 300
			}
		});

	}, [GlobalSettingStore.GlobalSetting])
	return (
		<>
			<ProjectsHeader />
			<br />
			<br />

			<section className="projects-section">
				<div className="container">
					<div className="row">

						<div className="controls">
							<ul className="list-inline">
								<li className="active">
									<span className="hvr-underline-from-left filter" data-filter="all">{i18n.t("AllProjects")}</span>
								</li>
								<li>
									<span className="hvr-underline-from-left filter" data-filter=".enerji">{i18n.t("Enerji")}</span>
								</li>
								<li>
									<span className="hvr-underline-from-left filter" data-filter=".hava">{i18n.t("HavaAyristirma")} </span>
								</li>
								<li>
									<span className="hvr-underline-from-left filter" data-filter=".gaz">{i18n.t("GazTemizleme")} </span>
								</li>
								<li>
									<span className="hvr-underline-from-left filter" data-filter=".metal">{i18n.t("Metallueloji")} </span>
								</li>
								<li>
									<span className="hvr-underline-from-left filter" data-filter=".sulfur">{i18n.t("Sulfurukasit")} </span>
								</li>
								<li>
									<span className="hvr-underline-from-left filter" data-filter=".maden">{i18n.t("Maden")} </span>
								</li>
								<li>
									<span className="hvr-underline-from-left filter" data-filter=".endustriyel">{i18n.t("EndustrielTesisler")} </span>
								</li>
								<li>
									<span className="hvr-underline-from-left filter" data-filter=".gubre">{i18n.t("Gubre")} </span>
								</li>
								<li>
									<span className="hvr-underline-from-left filter" data-filter=".altin">{i18n.t("AltinLeach")} </span>
								</li>
							</ul>
						</div>

						<div className="thm-container">

							<div className="col-md-4 mix item enerji">
								<Link to="/project/Wartsilamakina">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture3.jpg"
												alt="Wartsila Makina Enerji Santrali"
												style={{ objectFit: "cover", width: "100%", }}
											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Enerji")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("enerjibaslik1")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item enerji">
								<Link to="/project/Atikisi">
									<Card max-height={500}>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture6.jpg"
												alt="Atık Isı Kazan Firması"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Enerji")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("enerjibaslik2")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item enerji">
								<Link to="/project/Desox">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture7.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Enerji")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("enerjibaslik3")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item enerji">
								<Link to="/project/Denox">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture8.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Enerji")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("enerjibaslik4")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item enerji">
								<Link to="/project/Siemens">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture9.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Enerji")}
												</Typography>
												<Typography style={{fontSize:12}} variant="body2" color="text.secondary">
													{i18n.t("enerjibaslik5")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item enerji">
								<Link to="/project/Nuhcimento">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture12.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Enerji")}
												</Typography>
												<Typography  style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("enerjibaslik6")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item blue enerji">
								<Link to="/project/Komuryakitlikazan">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture15.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Enerji")}
												</Typography>
												<Typography style={{fontSize:11}} variant="body2" color="text.secondary">
													{i18n.t("enerjibaslik7")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item metal">
								<Link to="/project/Aliminyumdokum">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture23.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Metallueloji")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("metalurjibaslik1")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item gaz">
								<Link to="/project/Toztutmasist">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture28.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("GazTemizleme")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("toztutmabaslik1")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item enerji">
								<Link to="/project/Samsun">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture43.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Enerji")}
												</Typography>
												<Typography  style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("enerjibaslik8")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item enerji">
								<Link to="/project/Etialuminyum">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture49.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Enerji")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("enerjibaslik9")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item sulfur">
								<Link to="/project/Sulfirikasid">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture56.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Sulfurukasit")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("sulfirbaslik1")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item endustriyel">
								<Link to="/project/Amonyakdepolama">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture65.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("EndustrielTesisler")}
												</Typography>
												<Typography  style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("amonyakbaslik1")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item endustriyel">
								<Link to="/project/Asitdepolama">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture68.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("EndustrielTesisler")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("asitbaslik1")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item hava">
								<Link to="/project/Kriyojenikhava">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture73.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("HavaAyristirma")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("kriyojenikbaslik1")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div><div className="col-md-4 mix item gubre">
								<Link to="/project/Amonyumsulfat">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture76.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Gubre")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("amonyumbaslik1")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item metal">
								<Link to="/project/Anotbakir">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojelernew/CardPicture84.jpg"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Metallueloji")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("metalurjibaslik2")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item maden">
								<Link to="/project/Siirtbakir">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojeler2/CardResim2.png"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Maden")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("madenbaslik1")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item altin">
								<Link to="/project/Kirgizistanaltin">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojeler2/CardResim12.png"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("AltinLeach")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("madenbaslik2")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item maden">
								<Link to="/project/Adiyamanbakir">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojeler2/CardResim15.png"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Maden")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("madenbaslik3")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>
							<div className="col-md-4 mix item metal">
								<Link to="/project/Etibakir">
									<Card>
										<CardActionArea>
											<CardMedia
												component="img"
												height="140"
												image="../../../img/tamamlananprojeler2/CardResim20.png"
												alt="green iguana"
												style={{ objectFit: "cover" }}

											/>
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i18n.t("Metallueloji")}
												</Typography>
												<Typography style={{fontSize:14}} variant="body2" color="text.secondary">
													{i18n.t("metalurjibaslik3")}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Link>
							</div>


						</div>

					</div>

				</div>

			</section>
		</>
	);
}
const ProjectPageComponent = observer(ProjectPage);
export default ProjectPageComponent;
