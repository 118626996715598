import React, { useEffect, useState } from 'react';
import GlobalSettingStore from '../../Stores/GlobalSettingStore';




const ZHSeriesBlower: React.FC = () => {
  const [numPages, setNumPages] = useState<number | null>(null); // numPages state'inin tipi açıkça belirtilmiş
  const [error, setError] = useState("null");

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages); // Burada numPages'in türünü açıkça tanımladık
  };

  const onDocumentLoadError = (error: any) => {
    console.error("PDF yükleme hatası:", error);
    setError("PDF yüklenirken bir hata oluştu.");
  };
  useEffect(() => {
    window.scrollTo(0, 0);

}, [GlobalSettingStore.GlobalSetting])
  return (
    <>
      <div>
      <iframe src="/pdfjs/web/viewer.html?file=/pdf/ProductsCatalogue/RootsBlower/ZHSeriesBlower.pdf" 
      width="100%" 
      height="900" 
      frameBorder="0" 
      scrolling="no"></iframe>
      </div>
      <div>

      </div>
    </>
  );
};

export default ZHSeriesBlower;
