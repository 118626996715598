import React, { useEffect, useState } from 'react';
import GlobalSettingStore from '../../Stores/GlobalSettingStore';




const ZSHVZLBSeriesVacuumPump: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

}, [GlobalSettingStore.GlobalSetting])
  return (
    <>
      <div>
      <iframe src="/pdfjs/web/viewer.html?file=/pdf/ProductsCatalogue/RootsBlower/ZSH-VZLBSeriesVacuumPump.pdf" 
      width="100%" 
      height="900" 
      frameBorder="0" 
      scrolling="no"></iframe>
       
      </div>
      <div>

      </div>
    </>
  );
};

export default ZSHVZLBSeriesVacuumPump;
