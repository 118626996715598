import { useEffect, useState } from "react";
import i18n from "../common/languageConf";
import GlobalSettingStore from "../Stores/GlobalSettingStore";
import { observer } from "mobx-react";
import SolutionEnerji from '../components/solution/enerji'
import SolutionMaden from '../components/solution/maden'
import SolutionAirSperation from '../components/solution/AirSperation'
import SolutionMetallurgy from '../components/solution/metallurgy'
import SolutionSulphuric from '../components/solution/sulphuric'
import SolutionEngineering from '../components/solution/engineering'
import SolutionPManagement from '../components/solution/pmanagement'
import SolutionFertilizer from '../components/solution/fertilizer'
import SolutionGasCleaning from '../components/solution/gascleaning'
import SolutionGoldLeach from '../components/solution/goldleach'
import SolutionIndustrialPlant from '../components/solution/Industrialplant'
import SolutionPowerMeter from '../components/solution/powermeter'
import { Link } from "react-router-dom";




function SolutionPage() {

	const [solution1, setsolution1] = useState(true)
	const [solution2, setsolution2] = useState(false)
	const [solution3, setsolution3] = useState(false)
	const [solution4, setsolution4] = useState(false)
	const [solution5, setsolution5] = useState(false)
	const [solution6, setsolution6] = useState(false)
	const [solution7, setsolution7] = useState(false)
	const [solution8, setsolution8] = useState(false)
	const [solution9, setsolution9] = useState(false)
	const [solution10, setsolution10] = useState(false)
	const [solution11, setsolution11] = useState(false)
	const [solution12, setsolution12] = useState(false)


	useEffect(() => {
		window.scrollTo(0, 0);

	}, [GlobalSettingStore.GlobalSetting])

	function selected(value: string) {
		setsolution1(false)
		setsolution2(false)
		setsolution3(false)
		setsolution4(false)
		setsolution5(false)
		setsolution6(false)
		setsolution7(false)
		setsolution8(false)
		setsolution9(false)
		setsolution10(false)
		setsolution11(false)
		setsolution12(false)

		if (value == "1") {
			setsolution1(true)
		}
		if (value == "2") {
			setsolution2(true)
		}
		if (value == "3") {
			setsolution3(true)
		}
		if (value == "4") {
			setsolution4(true)
		}
		if (value == "5") {
			setsolution5(true)
		}
		if (value == "6") {
			setsolution6(true)
		}
		if (value == "7") {
			setsolution7(true)
		}
		if (value == "8") {
			setsolution8(true)
		}
		if (value == "9") {
			setsolution9(true)
		}
		if (value == "10") {
			setsolution10(true)
		}
		if (value == "11") {
			setsolution11(true)
		}

	}
	return (
		<>
			<section className="header-title">
				<div className="container">
					<div className="row">
						<div className="title">
							<h2>{i18n.t("SOLUTION")}</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="page-nav">
				<div className="container">
					<div className="row">
						<ul>

							<li><Link style={{fontWeight:"bold", fontSize:"14px"}} to={"/"}> {i18n.t("HOME")}</Link></li>
							<li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
							<li><span>{i18n.t("SOLUTION")}</span></li>
						</ul>
					</div>
				</div>
			</section>
			<section className="Mechanical-engineering">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="special-links">
								<ul>
									<li><a className={solution1 ? "active" : ""} style={{ cursor: "pointer" }} onClick={() => { selected("1") }}>{i18n.t("Enerji")}</a></li>
									<li><a className={solution2 ? "active" : ""} onClick={() => { selected("2") }} style={{ cursor: "pointer" }}>{i18n.t("Maden")}</a></li>
									<li><a className={solution3 ? "active" : ""} onClick={() => { selected("3") }} style={{ cursor: "pointer" }}>{i18n.t("Metallueloji")}</a></li>
									<li><a className={solution4 ? "active" : ""} onClick={() => { selected("4") }} style={{ cursor: "pointer" }}>{i18n.t("HavaAyristirma")}</a></li>
									<li><a className={solution5 ? "active" : ""} onClick={() => { selected("5") }} style={{ cursor: "pointer" }}>{i18n.t("Sulfurukasit")}</a></li>
									<li><a className={solution6 ? "active" : ""} onClick={() => { selected("6") }} style={{ cursor: "pointer" }}>{i18n.t("Muhendislik")}</a></li>
									<li><a className={solution7 ? "active" : ""} onClick={() => { selected("7") }} style={{ cursor: "pointer" }}>{i18n.t("ProjeYonetimi")}</a></li>
									<li><a className={solution8 ? "active" : ""} onClick={() => { selected("8") }} style={{ cursor: "pointer" }}>{i18n.t("Gubre")}</a></li>
									<li><a className={solution9 ? "active" : ""} onClick={() => { selected("9") }} style={{ cursor: "pointer" }}>{i18n.t("GazTemizleme")}</a></li>
									<li><a className={solution10 ? "active" : ""} onClick={() => { selected("10") }} style={{ cursor: "pointer" }}>{i18n.t("AltinLeach")}</a></li>
									<li><a className={solution11 ? "active" : ""} onClick={() => { selected("11") }} style={{ cursor: "pointer" }}>{i18n.t("Industrialplant")}</a></li>


								</ul>
							</div>
						</div>
						<div className="col-md-9">
							{solution1 && <SolutionEnerji />}
							{solution2 && <SolutionMaden />}
							{solution3 && <SolutionMetallurgy />}
							{solution4 && <SolutionAirSperation />}
							{solution5 && <SolutionSulphuric />}
							{solution6 && <SolutionEngineering />}
							{solution7 && <SolutionPManagement />}
							{solution8 && <SolutionFertilizer />}
							{solution9 && <SolutionGasCleaning />}
							{solution10 && <SolutionGoldLeach />}
							{solution11 && <SolutionIndustrialPlant />}


							<br />
							<br />

							<div className="questions-sol">

							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

const SolutionPageComponent = observer(SolutionPage);
export default SolutionPageComponent;