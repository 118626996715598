import HomeHeader from './components/HomeHeasder';
import Navigation from './components/Navigation';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Footer from './components/Footer';
import AboutPage from './pages/AboutPage';
import 'flag-icon-css/css/flag-icon.min.css';
//import '../src/css/style.css';
// import '../src/font-awesome/css/font-awesome.min.css';
import '../src/css/owl.carousel.css';
import '../src/css/owl.theme.default.css';
// import '../src/css/hover.css';
// import '../src/css/animate.css';
// import '../src/css/bootstrap.min.css';
import '../src/css/responsive.css';
import { LanguageProvider } from './common/LanguageContext';
import SolutionPage from './pages/SolutionPage';
import ProjectPage from './pages/ProjectPage';
import NewsPage from './pages/NewsPage';
import ShopsPage from './pages/ShopsPage';
import ContactPage from './pages/ContactPage';
import CompaniesPage from './pages/CompaniesPage';
import ProductPage from './pages/ProductPage';
import Energy from './components/projects/Energy';
import Mining from './components/projects/Mining';
import Enerji1 from './components/projects/Wartsilamakina';
import Atikisi from './components/projects/Atikisi';
import Desox from './components/projects/Desox';
import Denox from './components/projects/Denox';
import Siemens from './components/projects/Siemens';
import Nuhcimento from './components/projects/Nuhcimento';
import Aliminyumdokum from './components/projects/Aliminyumdokum';
import Komuryakitlikazan from './components/projects/Komuryakitlikazan';
import Toztutmasist from './components/projects/Toztutmasist';
import Samsun from './components/projects/Samsun';
import Etialuminyum from './components/projects/Etialuminyum';
import Sulfirikasid from './components/projects/Sulfirikasid';
import Amonyakdepolama from './components/projects/Amonyakdepolama';
import Asitdepolama from './components/projects/Asitdepolama';
import Kriyojenikhava from './components/projects/Kriyojenikhava';
import Amonyumsulfat from './components/projects/Amonyumsulfat';
import Anotbakir from './components/projects/Anotbakir';
import Siirtbakir from './components/projects/Siirtbakir';
import Kırgızistanaltin from './components/projects/Kırgızistanaltin';
import Etibakir from './components/projects/Etibakir';
import Adiyamanbakir from './components/projects/Adiyamanbakir';
import Nerin from './components/companies/Nerin';
import Zhanggu from './components/companies/ZhangguPage';
import ZhangguCompany from './components/companies/Zhanggucompany';
import ZhangguFAN from './components/companies/ZhangguFan';
import ZhangguCOMPRE from './components/companies/ZhangguComprehensive';
import ZhangguMVR from './components/companies/Zhanggumvr';
import RootsBlowerPageComponent from './components/companies/RootsBlowerPage';
import RotaryValvePageComponent from './components/companies/RotaryValvePage';
import TurboBlowerPageComponent from './components/companies/TurboBlowerPage';
import ComperePage from './components/companies/ComperePage';
import PowerMeterPage from './components/solution/powermeter';




function App() {
  return (
    <>
      <LanguageProvider>
        <HomeHeader />
        <Navigation />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/solution" element={<SolutionPage />} />
          <Route path="/projects" element={<ProjectPage />} />
          <Route path="/companies" element={<CompaniesPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/shops" element={<ShopsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/producta" element={<ProductPage />} />
          <Route path="/project/Energy" element={<Energy />} />
          <Route path="/project/Mining" element={<Mining />} />
          <Route path="/project/Wartsilamakina" element={<Enerji1 />} />
          <Route path="/project/Atikisi" element={<Atikisi />} />
          <Route path="/project/Desox" element={<Desox />} />
          <Route path="/project/Denox" element={<Denox />} />
          <Route path="/project/Siemens" element={<Siemens />} />
          <Route path="/project/Nuhcimento" element={<Nuhcimento />} />
          <Route path="/project/Komuryakitlikazan" element={<Komuryakitlikazan />} />
          <Route path="/project/Aliminyumdokum" element={<Aliminyumdokum />} />
          <Route path="/project/Toztutmasist" element={<Toztutmasist />} />
          <Route path="/project/Samsun" element={<Samsun />} />
          <Route path="/project/Etialuminyum" element={<Etialuminyum />} />
          <Route path="/project/Sulfirikasid" element={<Sulfirikasid />} />
          <Route path="/project/Amonyakdepolama" element={<Amonyakdepolama />} />
          <Route path="/project/Asitdepolama" element={<Asitdepolama />} />
          <Route path="/project/Kriyojenikhava" element={<Kriyojenikhava />} />
          <Route path="/project/Amonyumsulfat" element={<Amonyumsulfat />} />
          <Route path="/project/Anotbakir" element={<Anotbakir />} />
          <Route path="/project/Siirtbakir" element={<Siirtbakir />} />
          <Route path="/project/Kirgizistanaltin" element={<Kırgızistanaltin />} />
          <Route path="/project/Etibakir" element={<Etibakir />} />
          <Route path="/project/Adiyamanbakir" element={<Adiyamanbakir />} />
          <Route path="/companies/nerin" element={<Nerin />} />
          <Route path="/companies/zhanggu" element={<Zhanggu />} />
          <Route path="/companies/zhanggu/zhangguco" element={<ZhangguCompany />} />
          <Route path="/companies/zhanggu/zhanggufan" element={<ZhangguFAN />} />
          <Route path="/companies/zhanggu/zhanggucomprehensive" element={<ZhangguCOMPRE />} />
          <Route path="/companies/zhanggu/zhanggumvr" element={<ZhangguMVR />} />
          <Route path="/companies/zhanggu/rootsblower" element={<RootsBlowerPageComponent />} />
          <Route path="/companies/zhanggu/rotaryvalve" element={<RotaryValvePageComponent />} />
          <Route path="/companies/zhanggu/turboblower" element={<TurboBlowerPageComponent />} />
          <Route path="/companies/compere" element={<ComperePage />} />
          <Route path="/companies/compere/powermeter" element={<PowerMeterPage />} />


        </Routes>
        <Footer />
      </LanguageProvider>

    </>
  );
}

export default App;
