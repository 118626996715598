import { useEffect, useState } from "react";
import i18n from "../common/languageConf";
import GlobalSettingStore from "../Stores/GlobalSettingStore";
import { observer } from "mobx-react";
import { Link } from 'react-router-dom';
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
// @ts-ignore
import mixitup from "mixitup";
import ProjectsHeader from "../components/projects/ProjectsHeader";


function CompaniesPage() {
    useEffect(() => {
        // @ts-ignore
        var mixer = mixitup('.thm-container');
        var mixer = mixitup('.thm-container', {
            selectors: {
                target: '.blog-item'
            },
            animation: {
                duration: 300
            }
        });

    }, [GlobalSettingStore.GlobalSetting])
    return (
        <>

            <section className="header-title">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h2>{i18n.t("temsilcilikler")}</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="page-nav">
                <div className="container">
                    <div className="row">
                        <ul>
                            <li> <Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/"}> {i18n.t("HOME")}</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><span>{i18n.t("temsilcilikler")}</span></li>
                        </ul>
                    </div>
                </div>
            </section>


            <section className="projects-section">
                <div className="container">
                    <div className="row">
                        <div className="thm-container">
                            <div className="col-md-4 mix item enerji">
                                <Link to="/companies/nerin">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/NerinCardimg.png"
                                                alt="Nerin"
                                                style={{ objectFit: "cover", width: "100%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {i18n.t("nerin")}
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                    {i18n.t("nerincompany")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-md-4 mix item enerji">
                                <Link to="/companies/zhanggu">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/shandongimg/zhanggucompanyimg.png"
                                                alt="Nerin"
                                                style={{ objectFit: "cover", width: "100%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {i18n.t("zhanggu")}
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                    {i18n.t("zhanggucompany")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-md-4 mix item enerji">
                                <Link to="/companies/compere">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/shandongimg/compereCardimg.jpg"
                                                alt="Nerin"
                                                style={{ objectFit: "cover", width: "100%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                {i18n.t("compere")}
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                {i18n.t("comperecompany")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
const CompaniesPageComponent = observer(CompaniesPage);
export default CompaniesPageComponent;
