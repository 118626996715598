import { useEffect, useState } from "react";
import i18n from "../common/languageConf";
import { useLanguage } from "../common/LanguageContext";
import GlobalSettingStore from "../Stores/GlobalSettingStore";
import { observer } from "mobx-react";
import emailjs from '@emailjs/browser';
import { selectoptions } from "../common/selectoptions";
import { Link } from "react-router-dom";



function ContactPage() {
	const { language, changeLanguage } = useLanguage();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMesaage] = useState("");
	const [industry, setIndustry] = useState("");



	useEffect(() => {
		changeLanguage("tr")
	}, [GlobalSettingStore.GlobalSetting])

	function mailSender(e: any) {
		e.preventDefault();
		console.log("mailsender")
		emailjs.send("service_8fdhhye", "template_rmhs4tw", {
			from_name: name,
			message: message,
			reply_to: "info@epcproje.com",
			email: email,
			phone: phone,
			industry: industry,
		}, "5pC6zbBlEAFWRtcwS").then((response) => {
			console.log('SUCCESS!', response.status, response.text);
			alert(i18n.t("sendedMail"))
			setEmail("")
			setName("")
			setIndustry("")
			setMesaage("")
			setPhone("")
		}, (err) => {
			console.log('FAILED...', err);
		});
	}
	return (
		<>
			<section className="header-title">
				<div className="container">
					<div className="row">
						<div className="title">
							<h2>{i18n.t("Contactustoday")}</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="page-nav">
				<div className="container">
					<div className="row">
						<ul>

							<li>
							<Link style={{fontWeight:"bold", fontSize:"14px"}} to={"/"}> {i18n.t("HOME")}</Link>
							</li>
							<li>
								<i className="fa fa-angle-right" aria-hidden="true"></i>
							</li>
							<li>
								<span>{i18n.t("Contactustoday")}</span>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<section className="contact_us-second">
				<div className="container">
					<div className="row">

						<div className="col-md-8 col-sm-12 col-xs-12">
							<header>
								<h2>{i18n.t("Contactustoday")}</h2>
								<p>
									{i18n.t("Formoreinformation")}
								</p>
							</header>
							<form action="#" onSubmit={mailSender} className="contact-form">
								<div className="row">
									<div className="col-md-6">
										<div className="form-grp">
											<label>{i18n.t("Name")}</label>
											<input id="name" required placeholder={i18n.t("Name")} value={name} onChange={(e) => { setName(e.target.value) }} type="text" name="name" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-grp">
											<label>{i18n.t("Email")}</label>
											<input id="email" required placeholder={i18n.t("Email")} value={email} onChange={(e) => { setEmail(e.target.value) }} type="text" name="email" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-grp">
											<label>{i18n.t("Phone")}</label>
											<input id="phone" type="text" onChange={(e) => { setPhone(e.target.value) }} value={phone} required placeholder={i18n.t("Phone")} name="phone" />
										</div>
									</div>
									<div className="col-md-6">
										<label>{i18n.t("Sektor")}</label>
										<select className="form-control" style={{ height: 48,  padding: "10px 10px", border: "1px solid #6d6d6d", borderRadius: "0px",  color: "#b8b8b8"}} value={industry} onChange={(e) => { setIndustry(e.target.value) }}>
										{
											selectoptions()
										}
									</select>
								</div>
								<div className="col-md-12">
									<div className="form-grp">
										<label>{i18n.t("Message")}</label>
										<textarea className="form-control" cols={40} rows={5} placeholder="Message" id="inputComments"></textarea>

									</div>

								</div>
								<button className="btn btn-default hvr-bounce-to-right" type="submit">
									{i18n.t("SEND")}
								</button>
						</div>
					</form>
				</div>
				<div className="col-md-4 col-sm-8 col-xs-12 keep-in">
					<header>
						<h2>{i18n.t("GetinTouch")}</h2>
					</header>

					<ul className="social-icons">
						<li>
							<a href=""><i className="fa fa-facebook" aria-hidden="true"></i></a>
						</li>
						<li>
							<a href=""><i className="fa fa-twitter" aria-hidden="true"></i></a>
						</li>
						<li>
							<a href=""><i className="fa fa-dribbble" aria-hidden="true"></i></a>
						</li>
						<li>
							<a href=""><i className="fa fa-linkedin" aria-hidden="true"></i></a>
						</li>
						<li>
							<a href=""><i className="fa fa-vimeo" aria-hidden="true"></i></a>
						</li>
					</ul>
					<div className="contact-box">
						<h2>Locations</h2>

						<div className="address-box">
							<div className="icon-box">
								<i className="fa fa-map-marker" aria-hidden="true"></i>
							</div>

							<p className="address-text">
							<a style={{textDecoration:"none", color:"gray"}} href="https://maps.app.goo.gl/xQBtbGuCvbDGyRPa9" target='blank'>{i18n.t("adresmah")} <br /> {i18n.t("adresofis")}<br /> {i18n.t("adressehir")}</a>	
							</p>
						</div>
						<div className="address-box">
							<div className="icon-box">
								<i className="fa fa-phone" aria-hidden="true"></i>
							</div>

							<p className="address-text">
								(+90) 533 690 61 54
							</p>
						</div>
						<div className="address-box">
							<div className="icon-box">
								<i className="fa fa-envelope" aria-hidden="true"></i>
							</div>

							<p className="address-text">
								info@epcproje.com
							</p>
						</div>
						<div className="address-box">
							<div className="icon-box">
								<i className="fa fa-clock-o" aria-hidden="true"></i>
							</div>

							<p className="address-text">
								Mon - Sat: 09.00am to 18.00pm
							</p>
						</div>

					</div>

				</div>

			</div>
		</div >

			</section >
		<section className="map-section">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div id="contact-google-map"
							data-map-lat="33.958372"
							data-map-lng="-118.341827"
							data-map-zoom="12"
							data-icon-path="img/map-marker.png"
							data-map-title="Hanoi"
							data-markers="{&quot;marker-1&quot;: [33.958372, -118.341827, &quot;&lt;h4&gt;Main Head Office&lt;/h4&gt;&lt;p&gt;31 South Australia&lt;/p&gt;&quot;],		&quot;marker-2&quot;: [-38.021469, 145.118023, &quot;&lt;h4&gt;br/anch Office&lt;/h4&gt;&lt;p&gt;31 Main Boulevar 33154&lt;/p&gt;&quot;]		}" className="google-map"></div>

					</div>
				</div>
			</div>

		</section>



		</>
	);
}
const ContactPageComponent = observer(ContactPage);
export default ContactPageComponent;
